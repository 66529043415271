<template>
    <div class="profile">
        <div class="block-title">
            <div class="dot" />
            {{ $t('user.Profile.title') }}
        </div>
        <div class="profile-data">
            <v-form
                ref="form"
                v-model="valid"
            >
                <v-text-field
                    v-model="memberForm.lastName"
                    outlined
                    :label="$t('user.Profile.label[0]')"
                    placeholder=""
                    :hint="$t('user.Profile.hint[0]')"
                    persistent-hint
                    :rules="emptyRule"
                    class="border-orange-input"
                />
                <v-text-field
                    v-model="memberForm.firstName"
                    outlined
                    :label="$t('user.Profile.label[1]')"
                    placeholder=""
                    :hint="$t('user.Profile.hint[0]')"
                    persistent-hint
                    :rules="emptyRule"
                    class="border-orange-input"
                />
                <v-text-field
                    v-model="memberForm.phone"
                    outlined
                    :label="$t('user.Profile.label[2]')"
                    placeholder=""
                    :hint="$t('user.Profile.hint[0]')"
                    persistent-hint
                    :rules="phoneRule"
                    class="border-orange-input"
                />
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="memberForm.birthday"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="memberForm.birthday"
                            outlined
                            class="border-orange-input"
                            :placeholder="$t('user.Profile.label[3]')"
                            :hint="$t('user.Profile.hint[0]')"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            :rules="emptyRule"
                            append-icon="mdi-calendar"
                            v-on="on"
                            @click:append="menu = true"
                        />
                    </template>
                    <v-date-picker
                        v-model="memberForm.birthday"
                        no-title
                        scrollable
                    >
                        <v-spacer />
                        <v-btn
                            text
                            color="primary"
                            @click="saveBirthday"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                <v-text-field
                    v-model="account"
                    outlined
                    :label="$t('user.Profile.label[4]')"
                    placeholder=""
                    :hint="$t('user.Profile.hint[1]')"
                    persistent-hint
                    class="border-orange-input"
                    readonly
                />
                <v-row
                    align="center"
                    style="margin: 0"
                >
                    <v-text-field
                        v-model="memberForm.contactEmail"
                        outlined
                        :label="$t('user.Profile.label[5]')"
                        placeholder=""
                        :hint="$t('user.Profile.hint[0]')"
                        persistent-hint
                        :rules="emailRule"
                        class="border-orange-input"
                    />
                    <v-checkbox
                        v-model="mailEqual"
                        :label="$t('user.Profile.label[6]')"
                        class="contact-email-equal"
                        @change="handleMailEqual"
                    />
                </v-row>
            </v-form>
            <div class="action">
                <div
                    class="btn bg-orange color-white"
                    @click="updateMemberData"
                >
                    {{ $t('user.Profile.save') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            menu: false,
            mailEqual: false,
            memberForm: {
                lastName: '',
                firstName: '',
                phone: '',
                birthday: '',
                contactEmail: '',
            },
            account: '',
            // rules
            emptyRule: [(v) => !!v || this.$t('user.Profile.rule[0]')],
            phoneRule: [
                (v) => parsePhoneNumber(v, 'TW').isPossible() || this.$t('user.Profile.rule[1]'),
            ],
            emailRule: [
                (v) => !!v || this.$t('user.Profile.rule[0]'),
                (v) => /.+@.+\..+/.test(v) || this.$t('user.Profile.rule[2]'),
                (v) => !v.match(/\s/) || this.$t('user.Profile.rule[3]'),
            ],
        };
    },
    computed: {
        ...mapState('mAuth', { memberData: 'data' }),
    },
    created() {
        this.init();
    },
    mounted() {

    },
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapMutations('mLoading', ['setLoading']),
        init() {
            console.log('memberData', this.memberData);
            this.account = this.memberData.account;
            this.memberForm.lastName = this.memberData.data.lastName;
            this.memberForm.firstName = this.memberData.data.firstName;
            this.memberForm.phone = this.memberData.data.phone;

            this.memberForm.birthday = this.memberData.data.birthday ? moment(this.memberData.data.birthday).format('YYYY-MM-DD') : '';
            this.memberForm.contactEmail = this.memberData.data.contactEmail;
        },
        saveBirthday() {
            this.$refs.menu.save(this.memberForm.birthday);
            this.menu = false;
        },
        handleMailEqual() {
            if (this.mailEqual) {
                this.memberForm.contactEmail = this.account;
            }
        },
        updateMemberData() {
            this.$refs.form.validate();
            if (this.valid) {
                this.setLoading(true);
                API.User.UpdateInfo(this.memberForm)
                    .then(() => {
                        this.setAlert({
                            message: this.$t('alertMessage.update'),
                            color: 'success',
                        });
                        this.setLoading(false);
                    }).catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.update-fail'),
                            color: 'error',
                        });
                        this.setLoading(false);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.profile {
  .profile-data {
    width: 95%;
    max-width: 600px;
  }
  .contact-email-equal {
    margin-left: 10px;
    margin-top: -25px;
  }
}

@media screen and (max-width: 1024px) {
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;

    .action {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 385px) {
  .contact-email-equal {
    margin-left: 0;
  }
}
</style>
